import { initializeComponent } from "@/js/functions/init";
import checkoutNavigation from "@/js/components/checkout-navigation.vue";
import store from '@/js/store';
import { debounce } from "@/js/functions/debounce";

import { mapState } from 'vuex';


initializeComponent("payment-method", init);;

export default function init(rootElement) {
    new Vue({
        el: '#' + rootElement.id,
        store,
        props: ['purl'],
        data: {
            model: null,
            dupePo: null,
            acceptedDupe: true,
            isError: false,
            errorMessage: null,
            braintreeErrors: []
        },
        computed: {
            ...mapState([
                'triggerSubmit'
            ]),
        },
        watch: {
            triggerSubmit: function() {
                this.submit((success) => {
                    if (success) {
                        this.$store.dispatch('widgetSubmitted');
                    }
                });
            }
        },
        components: {
            checkoutNavigation
        },
        methods: {
            handlePoNumberInputDebounced: debounce(function({ type, target }) {
                this.model.PurchaseOrderNumber = null;
                this.dupePo = null;
                this.checkDupePo(target.value);
            }, 500),
            checkDupePo(poNumber) {
                var self = this;
                this.isLoading = true;

                let params = {
                    companyId: this.model.CompanyId,
                    customerId: this.model.CustomerId,
                    poNumber: poNumber,
                };

                this.model.PurchaseOrderNumber = poNumber;

                let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
                    .join("&");

                this.$http
                    .get(this.purl + "/uc/checkout/dupepo?" + query, {})
                    .then(response => {
                        if (
                            response.data &&
                            response.data.Status &&
                            response.data.Status == "success" &&
                            response.data.Data &&
                            response.data.Data.data
                        ) {
                            this.dupePo = response.data.Data.data;
                            this.isLoading = false;
                            if (this.dupePo && this.dupePo.Status != "success") {
                                var dupeEl = this.$el.querySelector('#HasDuplicatePurchaseOrderNumber');
                                if (dupeEl) {
                                    dupeEl.checked = true;
                                }

                                this.model.HasDuplicatePurchaseOrderNumber = true;
                                this.model.PurchaseOrderNumberDupeConfirmed = false;
                            } else {
                                this.model.HasDuplicatePurchaseOrderNumber = false;
                                this.model.PurchaseOrderNumberDupeConfirmed = true;
                                this.dupePo = null;
                            }
                        } else {
                            if (response.data.Status && response.data.Status == "failed") {
                                this.errorMessage = response.data.Message;
                                if (response.data.Data && response.data.Data.stacktrace) {
                                    console.log(response.data.Data.stacktrace);
                                }
                                this.isError = true;
                                this.$toastify.toastError(this.errorMessage);
                            }
                            this.dupePo = null;
                            this.isLoading = false;
                        }
                    });
            },
            submit: function(callback) {
                var fields = this.$el.querySelectorAll('input[name]');
                var requestData = {};

                if (!callback) {
                    callback = function() {};
                }

                for (var field of fields) {
                    if (field.type == 'radio') {
                        if (field.checked) {
                            requestData[field.name] = field.value;
                        }
                    } else if (field.type == 'checkbox') {
                        requestData[field.name] = field.checked;
                    } else {
                        requestData[field.name] = field.value;
                    }
                }

                if (this.model) {
                    requestData['HasDuplicatePurchaseOrderNumber'] = this.model.HasDuplicatePurchaseOrderNumber;
                }


                if (this.model.IsDistributor) {
                    this.ucSubmit(requestData, callback);
                } else {
                    this.btSubmit(requestData, callback);
                }
            },
            btSubmit: function(requestData, callback) {
                const cardSVG = document.body.querySelector('[data-braintree-id="card-number-icon-svg"]');
                this.cardType = "unknown";
                if (cardSVG && cardSVG.getAttribute("xlink:href") != null && cardSVG.getAttribute("xlink:href").includes("#icon-")) {
                    this.cardType = cardSVG.getAttribute("xlink:href").replace("#icon-", "").trim().split("-").join(" ");
                }
                this.btinstance.requestPaymentMethod((err, payload) => {

                    console.log(err);
                    console.log(payload);

                    if (err || !payload) {
                        return;
                    }

                    if (payload.nonce) {
                        requestData['Nonce'] = payload.nonce;
                    }

                    this.ucSubmit(requestData, callback);
                });
            },
            ucSubmit: function(requestData, callback) {

                if (this.model) {
                    requestData['HasDuplicatePurchaseOrderNumber'] = this.model.HasDuplicatePurchaseOrderNumber
                }
                if (this.$refs && this.$refs["PurchaseOrderNumberDupeConfirmed"]) {
                    requestData['PurchaseOrderNumberDupeConfirmed'] = this.$refs["PurchaseOrderNumberDupeConfirmed"].checked;
                }

                let loader = this.$loading.show({ fullPage: true });

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'eec.checkout',
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: 2,
                                option: this.cardType,
                            }
                        }
                    }
                });

                this.$http.post(this.purl + '/uc/checkout/payment', requestData).then((response) => {
                    if (response.data) {
                        if (response.data.Status && response.data.Status == 'failed') {
                            //console.error(response.data.Message);
                            if (response.data.Message) {
                                this.errorMessage = response.data.Message;
                            }
                            this.isError = true;
                            const errors = response.data.Data.errors;
                            if (errors) {
                                var message = '';
                                for (var fieldName in errors) {
                                    message = message + errors[fieldName] + ' ';
                                }
                                this.$toastify.toastError(message);
                            }
                        } else {
                            this.isError = false;
                            var data = response.data;

                            if (data.Status) {
                                if (data.Status == 'success') {
                                    callback(true, '');
                                } else {
                                    callback(false, data.Message);
                                }
                            } else {
                                callback(false, '');
                            }
                        }
                    }

                    loader.hide();
                });
            },
            getParameterByName: function(name, url = window.location.href) {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            },
            selectDefalut: function() {
                var methodIsAvaialle = () => {
                    for (var method of this.model.AvailablePaymentMethods) {
                        if (method.Value == this.model.SelectedPaymentMethodId) {
                            return true;
                        }
                    }

                    return false;
                }

                if (!methodIsAvaialle() && this.model.AvailablePaymentMethods.length) {
                    this.model.SelectedPaymentMethodId = this.model.AvailablePaymentMethods[0].Value;
                }
            },
            getScriptObject: function(selector) {
                var scriptElement = rootElement.querySelector(selector);
                if (scriptElement === null) {
                    return null;
                } else {
                    return JSON.parse(scriptElement.innerHTML);
                }
            },
            initializeBraintree: function() {
                braintree.dropin.create({
                    authorization: this.model.ClientToken,
                    selector: '#dropin-container',
                    card: {
                        cardholderName: {
                            required: true
                        }
                    }
                }, (err, instance) => {
                    if (err) {
                        console.error(err);
                    } else {
                        this.btinstance = instance;
                    }
                });
            },
        },
        created: function() {
            var scriptElement = rootElement.querySelector('script[purl]');
            this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl;

            this.$store.commit('vuecreated', 'payment');

            let errors = this.getParameterByName('errorMessage');
            if (errors) {
                this.braintreeErrors = errors.split(';');
            }

            this.$http.get(this.purl + '/uc/checkout/payment', {}).then((response) => {
                if (response.data &&
                    response.data.Status &&
                    response.data.Status == 'success' &&
                    response.data.Data && response.data.Data.data) {

                    this.model = response.data.Data.data;
                    this.selectDefalut();

                    if (!this.model.IsDistributor) {
                        this.initializeBraintree();
                    }
                } else {
                    if (response.data.Status && response.data.Status == 'failed') {

                        this.errorMessage = response.data.Message;
                        if (response.data.Data && response.data.Data.stacktrace) {
                            console.log(response.data.Data.stacktrace);
                        }
                        this.isError = true;
                        this.$toastify.toastError(this.errorMessage);
                    }
                    this.model = null;
                }
            });
        }
    });
}