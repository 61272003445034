'use strict';
import Vue from 'vue';
window.Vue = Vue;

if (process.env.NODE_ENV === "development") {
    Vue.config.devtools = true;
} else {
    Vue.config.devtools = false;
}

// import VueRouter from 'vue-router';
// Vue.use(VueRouter);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import VueResource from 'vue-resource';
Vue.use(VueResource);

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, {
    // props
    color: '#0077d4',
    fullPage: true,
    canCancel: false,
    loader: 'spinner',
    width: 64,
    height: 64,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999
});

// import moment from 'moment';
// window.moment = moment;

//global components
import CustomerSelector from "@/js/components/customer-selector.vue";
Vue.component("customer-selector", CustomerSelector);

//import Modal from "@/js/components/modal.vue";
//Vue.component("Modal", Modal);

// Init Global Utils
import Toast from '@/js/utils/toastify';
Vue.prototype.$toastify = Toast;
import Loader from '@/js/utils/loader';
Vue.prototype.$loader = Loader;


// var initializeComponent = function(dataComponentName) {
//     if (typeof dataComponentName === 'string' && dataComponentName.length > 0) {
//         var querySelectorExpression = "[data-component='" + dataComponentName + "']";
//         if (window.personalizationManager) {
//             window.personalizationManager.addPersonalizedContentLoaded(function() {
//                 var componentRootElements = document.querySelectorAll(querySelectorExpression);
//                 for (var i = 0; i < componentRootElements.length; i++) {
//                     var rootElement = componentRootElements[i];
//                     if (rootElement) {
//                         import (
//                             /* webpackChunkName: '[request]' */
//                             `@/js/widgets/${dataComponentName}.js`)
//                         .then(({ default: InitWidget }) => {
//                             // use the widget module
//                             InitWidget(rootElement);

//                         }).catch(error => console.warn('An error occurred while loading the component', error));
//                     }

//                 }
//             });
//         } else {
//             document.addEventListener('DOMContentLoaded', function() {
//                 var componentRootElements = document.querySelectorAll(querySelectorExpression);
//                 for (var i = 0; i < componentRootElements.length; i++) {
//                     var rootElement = componentRootElements[i];
//                     if (rootElement) {
//                         import (
//                             /* webpackChunkName: '[request]' */
//                             `@/js/widgets/${dataComponentName}.js`)
//                         .then(({ default: InitWidget }) => {
//                             // use the widget module
//                             InitWidget(rootElement);

//                         }).catch(error => console.warn('An error occurred while loading the component', error));


//                     }
//                 }
//             });
//         }
//     }
// };

//global styles
import '../src/css/uc-vue-styles.css'

//filters
import "@/js/filters/currency";

// //widgets
// var mainWidgets = [
//     'currency-picker',
//     'cusomer-picker',
//     'shipto-picker',
//     'products',
//     'cart',
//     'address',
//     'shipping-method',
//     'payment-method',
//     'checkout-overview',
//     'mini-basket',
//     'product-deals',
//     'featured-products',
//     'users',
//     'account'
// ];

// mainWidgets.forEach(element => {
//     initializeComponent(element);
// });

//import "@/js/widgets/account";
import "@/js/widgets/address";
import "@/js/widgets/cart";
import "@/js/widgets/checkout-overview";
import "@/js/widgets/currency-picker";
import "@/js/widgets/customer-picker";
import "@/js/widgets/category-navigation";
import "@/js/widgets/facet-filter";
import "@/js/widgets/featured-products";
import "@/js/widgets/mini-basket";
import "@/js/widgets/payment-method";
import "@/js/widgets/product-deals";
import "@/js/widgets/products";
import "@/js/widgets/shipping-method";
import "@/js/widgets/shipto-picker";
import "@/js/widgets/dotstatemap";
