import { initializeComponent } from '@/js/functions/init'
import store from '@/js/store'
import SelectPage from '@/js/components/select-page.vue'

import APISTATE from '@/js/models/apistate'

import {
  INIT_SHIPTO_LIST,
  SET_ACTIVE_SHIPTO,
} from '@/js/store/types'

initializeComponent('shipto-picker', init)

export default function init(rootElement) {
  new Vue({
    el: `#${rootElement.id}`,
    store,
    components: {
      SelectPage,
    },
    props: ['purl'],
    data() {
      return {
        elementId: rootElement.id,
        selectedShipTo: null,
        model: null,
        errorMessage: null,
        searchFields: [
          'ShipToId',
          'CompanyName',
          'AddressLine1',
          'City',
          'State',
        ],
        columns: [
          { title: 'ShipTo', data: 'ShipToId' },
          { title: 'CompanyName', data: 'CompanyName' },
          { title: 'Address', data: 'AddressLine1' },
          { title: 'City', data: 'City' },
          { title: 'State', data: 'State' },
        ],
      }
    },
    computed: {
      apiLoading() {
        return this.$store.getters.shipToApiState === APISTATE.LOADING || this.$store.getters.shipToApiState === APISTATE.INIT
      },
      apiLoaded() {
        return this.$store.getters.shipToApiState === APISTATE.LOADED
      },
      apiError() {
        return this.$store.getters.shipToApiState === APISTATE.ERROR
      },
      isShipToDistributor() {
        return this.$store.getters.isShipToDistributor
      },
      shipToApiState() {
        return this.$store.getters.shipToApiState
      },
      availableShipTos() {
        return this.$store.getters.availableShipTos
      },
      activeShipToName() {
        return this.$store.getters.activeShipToName
      },
      activeShipTo() {
        return this.$store.getters.activeShipTo
      },
      store() {
        return this.$store.state
      },
    },
    mounted() {
      const scriptElement = rootElement.querySelector('script[purl]')
      this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl
      this.$store.dispatch(INIT_SHIPTO_LIST, this.purl)

      window.addEventListener('customerpicked', (e) => {
        const method = e.detail
        this.$store.dispatch(INIT_SHIPTO_LIST, this.purl)
      })
    },
    methods: {
      setActiveShipTo(e) {
        const shipToObject = e[0]
        if (shipToObject) {
          this.$http.post(`${this.purl}/uc/checkout/shipto`, {
            SelectedShipToId: shipToObject.ShipToId,
            SelectedShipToName: shipToObject.Name,
          }).then(function (response) {
            if (response.data) {
              if (response.data.Status && response.data.Status === 'failed') {
                this.errorMessage = response.data.Message
                this.isError = true
                this.$toastify.toastError(this.errorMessage)
              }
              else {
                this.isError = false
                this.errorMessage = ''
                const data = response.data.Data
                e.Value = data.ShipToId
                if (this.activeShipTo) {
                  // this.$toastify.toastInfo('Address selected');
                }
                this.$store.dispatch(SET_ACTIVE_SHIPTO, shipToObject.ShipToId)
              }
            }
            else {
              this.errorMessage = response.Message
              this.$toastify.toastError(this.errorMessage)
              this.isError = true
            }
          })
        }
      },
      showRow(row) {
        return `${row.ShipToId}: ${row.AddressLine1}, ${row.City}, ${row.State}`
      },
    },
  })
}
