import { initializeComponent } from "@/js/functions/init";
import store from '@/js/store';
import SelectPage from "@/js/components/select-page.vue"
import props from "@/js/functions/props";

initializeComponent("customer-picker", init);

export default function init(rootElement) {
    var initProps = props.parseProps(rootElement);
    new Vue({
        el: '#' + rootElement.id,
        store,
        propData: initProps,
        props: ['purl', 'isReadOnly', 'cartPageUrl'],
        components: {
            SelectPage
        },
        data: {
            elementId: rootElement.id,
            purl: null,
            isReadOnly: false,
            model: null,
            errorMessage: null,
        },
        mounted: function() {
            this.purl = [];
            this.isReadOnly = false;

            if (initProps) {
                this.purl = initProps.purl;
                this.isReadOnly = initProps.isReadOnly;
                this.cartPageUrl = initProps.cartPageUrl;
            }
            //this is done in customer-selector component now
            //this.$store.dispatch(INIT_CUSTOMER_LIST, this.purl);
        },
    });
}