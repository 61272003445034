﻿<template>
    <div v-if="model">
        <template v-if="type == 'text'">
            <label v-if="label.length" :class="labelClasses" :for="inputId">{{ label }}</label>
            <input :id="inputId" v-model="value" :class="inputClasses" :disabled="isDisabled"
                   :name="inputName" type="text" v-on:change="handleChange"
                   v-on:input="handleInput">
            <span :class="errorClasses">{{ errorMessage }}</span>
        </template>
    </div>
</template>
<script>
function debounce(fn, delay) {
    var timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export default {
    name: "inputFieldAddress",
    data: function () {
        return {
            value: null,
            errorMessage: null,
            processData: debounce(function (newVal) {
                //this.value = newVal
                this.value = newVal ? newVal[this.addressType][this.fieldName] : null;
            }, 1000)
        }
    },
    props: {
        model: {
            default: null
        },
        addressType: {
            default: null
        },
        label: {
            default: ''
        },
        fieldName: {
            default: ''
        },
        isDisabled: {
            default: false,
        },
        type: {
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        inputClasses: {
            default: ''
        },
        labelClasses: {
            default: ''
        },
        errorClasses: {
            default: 'field-validation-error text-danger'
        }
    },
    computed: {
        inputId: function () {
            return this.addressType + '_' + this.fieldName;
        },
        inputName: function () {
            return this.addressType + '.' + this.fieldName;
        }
    },
    created: function () {
        this.value = this.model ? this.model[this.addressType][this.fieldName] : null;
    },
    watch: {
        model(newVal) {
            this.processData(newVal)
        }
    },
    methods: {
        getValue: function () {
            return this.value;
        },
        handleInput: function () {
            this.errorMessage = '';
        },
        handleChange: function () {
            this.model[this.addressType][this.fieldName] = this.value;

            // this.validate((valid, message) => {
            //     this.errorMessage = message;
            // });
        },
        validate: function (callback) {
            var me = this;
            var value = this.getValue();
            var name = this.inputName;

            if (!this.required && !value) {
                callback(true, null);
                return;
            }

            this.$root.submit(name, false, callback);
        }
    }
};
</script>
