﻿<template>
    <label class="checkbox-item">
        <input
            :checked="value"
            :name="name"
            class="checkbox-input"
            type="checkbox"
            @change="$emit('input', $event.target.checked)"
        />
        <span class="checkbox-label">
            <slot></slot>
        </span>
    </label>
</template>
<script>
export default {
    name: "inputFieldCheckbox",
    props: {
        name: {
            default: ''
        },
        value: {
            default: null,
        }
    },
};
</script>
