import { initializeComponent } from "@/js/functions/init";
import checkoutNavigation from "@/js/components/checkout-navigation.vue";
import store from '@/js/store';
import selectPage from "@/js/components/select-page.vue"
import skeletonLoader from "@/js/components/skelton-loader.vue"
import APISTATE from "@/js/models/apistate"


import { mapState } from 'vuex';

import {
    INIT_SHIPPING_LIST,
    SET_ACTIVE_SHIPPING,
    SET_SHIPPING_INFO
} from '@/js/store/types'

initializeComponent("shipping-method", init);

export default function init(rootElement) {
    new Vue({
        el: '#' + rootElement.id,
        store,
        props: ['purl'],
        data: {
            isError: false,
            errorMessage: null,
            model: null,
            errorList: []
        },
        computed: {
            ...mapState([
                'triggerSubmit',
                'updateIteration'
            ]),
            apiLoading() {
                return this.$store.getters.shippingMethodApiState == APISTATE.LOADING || this.$store.getters.shippingMethodApiState == APISTATE.INIT;
            },
            apiLoaded() {
                return this.$store.getters.shippingMethodApiState == APISTATE.LOADED;
            },
            apiError() {
                return this.$store.getters.shippingMethodApiState == APISTATE.ERROR;
            },
            shippingMethodApiState() {
                return this.$store.getters.shippingMethodApiState;
            },
            shippingMethodApiState() {
                return this.$store.getters.shippingMethodErrorMessage;
            },
            checkoutModel() {
                return this.$store.getters.checkoutModel;
            },
            availableShippingMethods() {
                return this.$store.getters.availableShippingMethods;
            },
            activeShippingMethod() {
                return this.$store.getters.activeShippingMethod;
            },
            freightTermOptions() {
                return this.$store.getters.freightTermOptions;
            },
            vehicleTypeOptions() {
                return this.$store.getters.vehicleTypeOptions;
            },
            shipViaOptions() {
                return this.$store.getters.shipViaOptions;
            },
            store() {
                return this.$store.state
            },
            enableCarrierName() {
                return this.model.ShipVia == this.shipViaOptions.BestWay &&
                    (this.model.FreightTerms == this.freightTermOptions.ThirdParty || this.model.FreightTerms == this.freightTermOptions.Collect);
            },
            enableParcelAccount() {
                return (this.model.ShipVia == this.shipViaOptions.UPS || this.model.ShipVia == this.shipViaOptions.FreeUPS) &&
                    (this.model.FreightTerms == this.freightTermOptions.ThirdParty || this.model.FreightTerms == this.freightTermOptions.Collect);
            },
            enableFreightTerms() {
                return this.model.ShipVia != this.shipViaOptions.Pickup;
            },
            enableFreightAddress() {
                return this.model.ShipVia != this.shipViaOptions.Pickup && this.model.FreightTerms == this.freightTermOptions.ThirdParty;
            },
            enableVehicleType() {
                return this.model.ShipVia == this.shipViaOptions.BestWay;
            },
            enableTarpRequired() {
                return this.enableVehicleType() && this.model.VehicleType == this.vehicleTypeOptions.Flatbed;
            }
        },
        watch: {
            triggerSubmit: function() {
                this.submit((success) => {
                    if (success) {
                        this.$store.dispatch('widgetSubmitted');
                    }
                });
            },
            updateIteration: function() {
                this.$store.dispatch(INIT_SHIPPING_LIST, this.purl);
            },
            checkoutModel: function() {
                this.model = JSON.parse(JSON.stringify(this.checkoutModel.ShippingInfo));
            }
        },
        components: {
            checkoutNavigation,
            selectPage,
            skeletonLoader
        },
        methods: {
            setActiveShippingMethod: function(method) {
                if (method) {
                    this.$store.dispatch(SET_ACTIVE_SHIPPING, method[0].ShippingMethodId);
                    this.model.ShipVia = method[0].MethodCode;
                }
            },
            setShippingInfo: function() {
                this.$store.dispatch(SET_SHIPPING_INFO, JSON.parse(JSON.stringify(this.model)));
            },
            displayError: function(err) {
                var errStr = "";
                for (var i = 0; i < err.length; i++) {
                    if (i > 0) errStr += "<br />";
                    errStr += err[i];
                }
                return errStr;
            },
            submit: function(callback) {
                let loader = this.$loading.show();
                var requestData = {
                    SelectedShippingMethodId: this.activeShippingMethod,
                    FreightDetails: this.model
                };

                if (!callback) {
                    callback = function() {};
                }

                this.$http.post(this.purl + '/uc/checkout/shipping', requestData).then((response) => {
                    if (response.data) {
                        if (response.data.Status && response.data.Status == 'failed') {
                            if (response.data.Message) console.error(response.data.Message);
                            this.errorMessage = response.data.Message || "";
                            this.errorList = response.data.Data.errors;
                            this.isError = true;
                            this.$toastify.toastError(this.errorMessage);

                        } else {
                            this.isError = false;
                            var data = response.data;

                            if (data.Status) {
                                if (data.Status == 'success') {
                                    callback(true, '');
                                } else {
                                    this.errorList = data.Data.errors;
                                    loader.hide();
                                    callback(false, data.Message);
                                }
                            } else {
                                loader.hide();
                                callback(false, '');
                            }
                        }
                    }
                });
            }

        },
        mounted: function() {
            var scriptElement = rootElement.querySelector('script[purl]');
            this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl;
            this.$store.commit('vuecreated', 'shipping');
            this.$store.dispatch(INIT_SHIPPING_LIST, this.purl);

            document.addEventListener("shipping:select", (e) => {
                const method = e.detail;
                this.$store.dispatch(SET_ACTIVE_SHIPPING, method.ShippingMethodId);
                this.model.ShipVia = method.MethodCode;
            });
        }
    });
}