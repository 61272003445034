<template>
    <div class="container">
        <div v-show="loading" class="row">
            <!-- <div class="col-xl-4 pt-5">
                <div class="placeholder wave">
                    <div class="square"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="col-xl-4 pt-5">
                <div class="placeholder wave">
                    <div class="circle"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div> -->
            <div class="col-xl-4 pt-5">
                <div class="placeholder wave">
                    <div class="rectangle"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonLoader',
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.setLoadingState(false)
        }, 5000)
    },
    methods: {
        setLoadingState(value) {
            this.loading = value
        }
    }
}
</script>
