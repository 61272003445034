import { mapState } from 'vuex'
import { initializeComponent } from '@/js/functions/init'
import store from '@/js/store'

const cartlistItem = function (sku, variantSku, listPrice, userPrice, price, qty, packaging, minOrder, orderFactor) {
  this.Sku = sku
  this.VariantSku = variantSku
  this.ListPrice = listPrice
  this.UserPrice = userPrice
  this.Price = price
  this.Quantity = qty
  this.Packaging = packaging
  this.MinOrder = minOrder
  this.OrderFactor = orderFactor
  this.equals = function (other) {
    return other.Sku === this.Sku && other.VariantSku === this.VariantSku
  }
}
window.eventEmitter = window.eventEmitter || new EventTarget()

initializeComponent('mini-basket', init)

export default function init(rootElement) {
  new Vue({
    el: `#${rootElement.id}`,
    store,
    props: ['purl'],
    data() {
      return {
        cartList: [],
        currentCustomer: null,
        model: null,
        isError: false,
        errorMessage: null,
        isVisible: false,
      }
    },
    computed: {
      ...mapState([
        'updateIteration',
      ]),
      isCustomerDistributor() {
        return this.$store.getters.isCustomerDistributor
      },
      availableCustomers() {
        return this.$store.getters.availableCustomers
      },
      activeCustomer() {
        return this.$store.getters.activeCustomer
      },
    },
    watch: {
      updateIteration() {
        this.fetchData()
      },
      activeCustomer(newCustomer, oldCustomer) {
        this.currentCustomer = newCustomer
        // was called getCart which doesnt exist
        this.fetchData()

        if (this.model && this.model.OrderLines) {
          let needsUpdate = false
          for (const item of this.model.OrderLines) {
            const orderlineId = item.OrderLineId

            if (item.PrivateLabelCustomers) {
              let canOderPrivateLabelItem = false
              for (const customer in item.PrivateLabelCustomers) {
                if (newCustomer === customer.CustomerId)
                  canOderPrivateLabelItem = true
              }

              if (!canOderPrivateLabelItem) {
                item.Quantity = 0
                needsUpdate = true
                const warningMessage = `Cannot order private label item ${item.VariantSku} for your current selected customer id ${newCustomer}`
                this.$toastify.toastWarning(warningMessage)
              }
            }
          }

          if (needsUpdate)
            this.updateCartItems()
        }
      },
    },
    mounted() {
      if (eventEmitter) {
        eventEmitter.addEventListener('syncMiniBasket', (evt) => {
          console.log('received sync mini basket event', evt);
          if (evt.detail) {
            if (evt.detail.cartlist) {
              // this.cartList = evt.detail.cartlist;
            }
          }
          this.isVisible = true
          this.fetchData()
        })
      }
      else {
        console.log('no event emitter')
      }
    },
    unmounted() {
      if (eventEmitter)
        eventEmitter.removeEventListener('syncMiniBasket', console.log)
      else
        console.log('no event emitter')
    },
    created() {
      const scriptElement = rootElement.querySelector('script[purl]')
      this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl
      // console.log('mini basket init');
      this.fetchData()
    },
    methods: {
      fetchData(itemId, itemQuantity, cartItem) {
        let params = {}
        let url = `${this.purl}/uc/checkout/mini-basket`
        if (itemId) {
          params = {
            params: {
              orderLineId: itemId,
              updateQuantity: itemQuantity,
            },
          }

          url = `${url}/update`
        }
        console.log('get minibasket data with params', params)

        this.$http.get(url, params).then((response) => {
          if (response.data
                        && response.data.Status
                        && response.data.Status === 'success'
                        && response.data.Data
                        && response.data.Data.data) {
            this.model = response.data.Data.data

            // Make line items globally accessible (for Google Analytics)
            console.log('globalThis.cart', response.data.Data.data.OrderLines)
            globalThis.cart = response.data.Data.data.OrderLines
          }

          if (response.data
                        && response.data.Status
                        && response.data.Status === 'warning'
                        && response.data.Data
                        && response.data.Data.data) {
            this.model = response.data.Data.data
            this.errorMessage = response.data.Message

            this.$toastify.toastWarning(this.errorMessage)

            // Make line items globally accessible (for Google Analytics)
            console.log('globalThis.cart warning', response.data.Data.data.OrderLines)

            globalThis.cart = response.data.Data.data.OrderLines
          }
        })

        if (cartItem) {
          const newCartItemEvent = document.createEvent('CustomEvent')
          newCartItemEvent.initCustomEvent('cartlistManager:addToList', true, true, { cartItem })
          window.dispatchEvent(newCartItemEvent)
        }
        else {
          const cartlistUpdateEvent = document.createEvent('CustomEvent')
          cartlistUpdateEvent.initCustomEvent('cartlistManager:updateUI', true, true, {})
          window.dispatchEvent(cartlistUpdateEvent)
        }
      },
      getUserPrice(orderLine) {
        if (this.model.IsLoggedIn)
          return orderLine.UserPrice
        else
          return orderLine.ListPrice
      },
      getListPrice(orderLine) {
        if (this.model.IsLoggedIn)
          return orderLine.ListPrice
        else
          return orderLine.UserPrice
      },
      updateCartItems(orderLineId, updateQuantity) {
        const model = this.model
        const orderlineKeyValue = []
        const loader = this.$loading.show()

        let activeCustomer = this.activeCustomer
        if (!activeCustomer)
          activeCustomer = this.currentCustomer

        const currentKeyValue = { orderLineId, updateQuantity }
        orderlineKeyValue.push(currentKeyValue)

        this.$http.post(`${this.purl}/uc/checkout/mini-basket/update`, {
          OrderLineId: orderLineId,
          UpdateQuantity: updateQuantity,
        }).then(function (response) {
          if (response.data) {
            if (response.data.Status && response.data.Status === 'failed') {
              this.errorMessage = response.data.Message
              this.isError = true

              this.$toastify.toastError(this.errorMessage)
            }
            else {
              this.isError = false
              this.errorMessage = ''
              const data = response.data.Data
              if (response.data.Status && response.data.Status === 'warning')
                this.$toastify.toastWarning(this.errorMessage)

              const updatedFields = ['Total', 'OrderTotal']
              const orderLineArray = []

              for (const field of updatedFields)
                model[field] = data[field]

              for (const updatedItem of data.OrderLines) {
                for (const currentItem of model.OrderLines) {
                  if (currentItem.OrderLineId === updatedItem.OrderlineId)
                    orderLineArray.push(Object.assign({}, currentItem, updatedItem))
                }
              }
              console.log('mini-basket updates', orderLineArray)

              model.OrderLines = orderLineArray
            }
          }
          else {
            this.errorMessage = response.Message
            this.$toastify.toastError(this.errorMessage)

            this.isError = true
          }
          loader.hide()
        })
      },
      removeCartItem(itemId) {
        const loader = this.$loading.show()
        const selector = `[data-order-line-id="${itemId}"]`
        const item = document.querySelector(selector)
        let newQuantity = 1
        // Google Analytics
        window.dataLayer = window.dataLayer || []
        let productQuantity = 1
        const parsedQuantity = parseInt(item.dataset.quantity)

        if (parsedQuantity)
          productQuantity = parsedQuantity

        const productData = {
          id: item.dataset.sku,
          name: item.dataset.name,
          category: '',
          variant: item.dataset.variantSku,
          brand: item.dataset.brand,
          quantity: productQuantity,
          dimension3: 'Ecommerce',
          metric5: 12,
          metric6: 1002,
        }

        window.dataLayer.push({
          event: 'eec.remove',
          ecommerce: {
            add: {
              actionField: {
                list: 'Shopping cart',
              },
              products: [productData],
            },
          },
        })

        this.$http.post(`${this.purl}/uc/checkout/mini-basket/remove-orderline`, {
          orderlineId: itemId,
        }).then(function (response) {
          if (response.data) {
            if (response.data.Status && response.data.Status === 'failed') {
              this.errorMessage = response.data.Message
              this.isError = true
              this.$toastify.toastError(this.errorMessage)
            }
            else {
              this.isError = false
              this.model = response.data.Data.data

              // Make line items globally accessible (for Google Analytics)
              // globalThis.cart = response.data.Data.data.OrderLines;
              // console.log('got model for remove', response.data);
              newQuantity = response.data.Data.data.NumberOfItems
            }
          }
          else {
            this.$toastify.toastWarning(response)
          }
          loader.hide()
        })

        const removeItem = new cartlistItem(
          item.getAttribute('data-sku'),
          item.getAttribute('data-variant-sku'),
          item.getAttribute('data-list-price'),
          item.getAttribute('data-user-price'),
          item.getAttribute('data-price'),
          item.getAttribute('data-quantity'),
          item.getAttribute('data-packaging'),
          item.getAttribute('data-min-order'),
          item.getAttribute('data-order-factor'))

        const removeCartItemEvent = document.createEvent('CustomEvent')
        removeCartItemEvent.initCustomEvent('cartlistManager:removeFromList', true, true, { cartItem: removeItem })
        window.dispatchEvent(removeCartItemEvent)
        // this.fetchData(itemId, newQuantity, removeItem);
      },
      addCartItem(itemId) {
        const selector = `[data-order-line-id="${itemId}"]`
        const item = document.querySelector(selector)
        const quantity = item.getAttribute('data-quantity')
        let orderFactor = item.getAttribute('data-order-factor')

        if (!orderFactor)
          orderFactor = 0

        const newQuantity = parseInt(quantity) + parseInt(orderFactor)

        const newItem = new cartlistItem(
          item.getAttribute('data-sku'),
          item.getAttribute('data-variant-sku'),
          item.getAttribute('data-list-price'),
          item.getAttribute('data-user-price'),
          item.getAttribute('data-price'),
          newQuantity,
          item.getAttribute('data-packaging'),
          item.getAttribute('data-min-order'),
          item.getAttribute('data-order-factor'))

        this.fetchData(itemId, newQuantity, newItem)
      },
      subtractCartItem(itemId) {
        const selector = `[data-order-line-id="${itemId}"]`
        const item = document.querySelector(selector)
        const quantity = item.getAttribute('data-quantity')
        const minOrder = item.getAttribute('data-min-order')
        const orderFactor = item.getAttribute('data-order-factor')

        const newQuantity = parseInt(quantity) - parseInt(orderFactor)

        if (newQuantity < 1) {
          this.removeCartItem(itemId)
          return
        }

        const newItem = new cartlistItem(
          item.getAttribute('data-sku'),
          item.getAttribute('data-variant-sku'),
          item.getAttribute('data-list-price'),
          item.getAttribute('data-user-price'),
          item.getAttribute('data-price'),
          newQuantity,
          item.getAttribute('data-packaging'),
          item.getAttribute('data-min-order'),
          item.getAttribute('data-order-factor'))
        if (itemId && newQuantity && newItem)
          this.fetchData(itemId, newQuantity, newItem)
        else
          this.fetchData()
      },
      toggleVisibility(e) {
        e.preventDefault()
        this.isVisible = !this.isVisible
      },
    },
    filters: {
      removeSpace(value) {
        return value.split(' ').join('')
      },
    },
  })
}
