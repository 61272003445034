﻿import { initializeComponent } from "@/js/functions/init";
import currencySelector from '@/js/components/currency-selector.vue';

initializeComponent("currency-picker", init);

export default function init(rootElement) {
    const scriptElement = rootElement.querySelector('script[data-items]');
    const items = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).items;
    new Vue({
        el: '#' + rootElement.id,
        props: {
            purl: {
                type: String,
                default: false
            }
        },
        data: {
            items: items,
        },
        components: {
            currencySelector
        }
    });
};