import { initializeComponent } from "@/js/functions/init";
import {
    listenForPrevBtnClick,
    listenForNextBtnClick,
    disablePrevNextBtns,
} from "@/js/functions/embla-buttons";
import props from "@/js/functions/props";

initializeComponent("product-deals", init);

export default function init(rootElement) {
    var initProps = props.parseProps(rootElement);
    const scriptElement = rootElement.querySelector("script[data-items]");
    const items = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).items;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 3,
    });
    new Vue({
        el: "#" + rootElement.id,
        propData: initProps,
        props: ["purl"],
        data: {
            items: items,
            isLoggedIn: false,
            isDistributor: false,
            prices: {},
        },
        components: {},
        methods: {
            getPrices: function (productCodes, productQuantities) {
                var params = {};
                var url = "/authapi/productprices";
                if (productCodes) {
                    params = {
                        params: {
                            productCodeJson: JSON.stringify(productCodes),
                            productQuantityJson: JSON.stringify(productQuantities),
                        },
                    };
                }

                this.$http.get(url, params).then((response) => {
                    if (response && response.data) {
                        this.isLoggedIn = response.data.IsLoggedIn;
                        this.isDistributor = response.data.IsDistributor;
                        this.prices = response.data.Prices;
                        if (this.prices) {
                            for (let index = 0; index < items.length; index++) {
                                const product = items[index];
                                var price = this.prices[product.VariantSku];
                                if (price) {
                                    if (price.ListPrice) {
                                        product.ListPrice = price.ListPrice;
                                        product.ListPriceDisplay = formatter.format(
                                            price.ListPrice
                                        );
                                    }

                                    if (price.UserPrice) {
                                        product.UserPrice = price.UserPrice;
                                        product.UserPriceDisplay = formatter.format(
                                            price.UserPrice
                                        );
                                    }

                                    if (price.Price) {
                                        product.Price = price.Price;
                                        product.PriceDisplay = formatter.format(price.Price);
                                    }
                                    //use Vue.Set for collection reactivity
                                    Vue.set(this.items, index, product);
                                }
                            }
                        }
                    } else {
                        this.prices = {};
                        this.isLoggedIn = false;
                        this.isDistributor = false;
                    }
                });
            },
        },
        mounted: function () {
            //setup carousel widget assume cdn script for EmblaCarouse and AutoPlay
            const wrap = document.querySelector(".product-wrapper .embla");
            const viewPort = wrap.querySelector(".product-wrapper .embla__viewport");
            const prevBtn = wrap.querySelector(".product-wrapper .embla__button--prev");
            const nextBtn = wrap.querySelector(".product-wrapper .embla__button--next");

            const autoplay = EmblaCarouselAutoplay(
                { delay: 3000, stopOnInteraction: true },
                (emblaRoot) => emblaRoot.parentElement
            );

            const embla = EmblaCarousel(
                viewPort,
                { slidesToScroll: 1, align: "start", skipSnaps: false, loop: true },
                [autoplay]
            );
            const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

            listenForPrevBtnClick(prevBtn, embla, autoplay);
            listenForNextBtnClick(nextBtn, embla, autoplay);

            embla.on("init", disablePrevAndNextBtns);
            embla.on("select", disablePrevAndNextBtns);
        },
        created: function () {
            this.purl = initProps.purl;
            if (this.items) {
                var productCodes = this.items.map(function (p) {
                    return p.VariantSku;
                });
                var productQuantities = this.items.map(function (p) {
                    return p.MinOrder;
                });
                this.getPrices(productCodes, productQuantities);
            }
        },
    });
}
